.score-info {
    margin-bottom: 2rem;
    padding: .6rem 1.6rem;
    border-radius: 4px;
}

.score-info .score {
    font-weight: 500;
    margin-bottom: .2rem;
}

.score-info.sufficient {
    background-color: #e5e186;
    color: #663c00;
}
.score-info.good {
    background-color: #cfc;
    color: #1e4620;
}
.score-info.verygood {
    background-color: #32b332;
    color: #e8f5e9
}
.score-info.excellent {
    background-color: #1a941a;
    color: #e8f5e9;
}

