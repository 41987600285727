main .list-page > .MuiToolbar-root {
    display: block; /* replace flex layout in order to make the line of filter fields wider */
}

.filter-field {
    flex-shrink: 0;
    flex-grow: 1;
    max-width: 100%;
    min-width: 33.32%;
}

.filter-field .MuiFormControl-root {
    flex-grow: 1;
    width: 100%;
}