.Card {
    min-width: 275px;
    margin-top: 1em; /* like in react-admin */
}

.textcontent, .textcontentlarger, .title, .h1, .h3, .externallink {
    font-family: Arial, Helvetica, sans-serif;
}

.textcontent {
    font-size: medium;
}

.textcontentlarger {
    font-size: large;
}

h1 {
    font-size: x-large;
}

h3 {
    font-size: large;
}

.externallink {
    font-size: medium;
    margin: 8px 8px;
}