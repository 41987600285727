.resetcard {
    height: 30em;
    width: 40em;
    margin: 0;
    background: yellow;
}

.textcontent {
    font-size: 13px;
    white-space: pre-wrap;
}

.MuiButton-label .MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
    /* Progress on the button of primary color is not visible otherwise */
    color: white !important;
}

input::-ms-clear, input::-ms-reveal {
    /* trying to hide IE11 password eye icon, because we are drawing our own */
    display: none;
}

.loginPage .actions {
    margin-top: 1rem;
}