/* Only needed for footer on every page  */

body {
    background-color: #efefef;
}

div.layout {
    min-height: 85vh;
}

#footer {
    padding: 20px;
}