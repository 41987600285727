.show-page .textFieldInputLabel {
    color: black !important;
}

.show-page .MuiTypography-body2 {
    color: black !important;
    font-weight: bold !important;
    /*border-bottom: dashed 1px gray;*/
}

.show-page .formLayoutCell:not(:empty) {
    margin-bottom: 0.2em;
}

.actionButtons {
    display: block;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
}

.actionButtons :not(:first-child) {
    margin-left: .5rem;
}

.actionButtons > .MuiButton-label {
    align-items: center;
}