main .list-page > .MuiToolbar-root {
    display: block; /* replace flex layout in order to make the line of filter fields wider */
}

.filter-field {
    min-width: 15%;
}

.filter-field .MuiFormControl-root {
}

.MuiContainer-maxWidthLg {
    max-width: none !important;
}

.custom-filter-form {
    margin-top: 10px !important; /* Override the margin-top with a specific value */
}
