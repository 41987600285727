body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input.Mui-disabled:disabled {
    color: rgba(0, 0, 0, 0.70) /* much stronger shape than default ( */
}

div.MuiToolbar-root.MuiToolbar-regular .MuiButton-root {
    /* add space between adjacent toolbar buttons */
    margin-right: 1em;
}
