ul.validationList {
    list-style: none;
    padding-left: 0;
}

ul.validationList > li {
    padding: 1rem 0;
}
ul.validationList > li:not(:last-child) {
    border-bottom: 1px solid #e8e8e8
}